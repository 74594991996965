import { Box, Paper, Typography, Grid, Button } from "@mui/material";
import { useTranslate, useNotify, useDelete } from "react-admin";
import { RatingBadge } from "../RatingBadge";
import { useTheme } from '@mui/material/styles';
import { IconStarFilled, IconCopy, IconMessage, IconTrash } from '@tabler/icons-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ConversationAdminProps {
    meta: string;
    messages: string;
    id: string;
}

export const ConversationAdmin = ({ meta, messages, id }: ConversationAdminProps) => {
    const translate = useTranslate();
    const notify = useNotify();
    const theme = useTheme();
    const navigate = useNavigate();
    const [deleteOne] = useDelete();
    
    const parsedMeta = React.useMemo(() => {
        if (!meta) return {};
        try {
            return JSON.parse(meta);
        } catch (error) {
            console.error('Failed to parse meta:', error);
            return {};
        }
    }, [meta]);

    const handleCopy = (type: string) => {
        notify(translate(`resources.conversations.${type}_copied`), { type: 'info' });
    };

    const handleDelete = async () => {

        await deleteOne(
            'conversations',
            { id: id },
            {
                onSuccess: () => {
                    notify('resources.conversations.deleted', { type: 'info' });
                    navigate('/conversations');
                },
                onError: () => notify('resources.conversations.delete_error', { type: 'error' }),
            }
        );
    };

    return (
        <Paper sx={{
            p: theme.spacing(2),
            boxShadow: theme.shadows[0],
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box height="100%">
                <Box sx={{ mb: theme.spacing(2) }}>
                    <Typography variant="h5" fontWeight="bold">
                        {translate('resources.conversations.fields.admin')}
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(2) }}>
                                <IconStarFilled 
                                    size="1rem" 
                                    style={{ marginRight: 8 }}
                                />
                                <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                    {translate('resources.conversations.fields.rating')}:
                                </Typography>
                            </Box>
                            <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                {parsedMeta.rating ? <RatingBadge value={parsedMeta.rating} /> : 'Unrated'}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box>
                                <CopyToClipboard text={window.location.href} onCopy={() => handleCopy('url')}>
                                    <Button size="small" variant="outlined" startIcon={<IconCopy size="1.2rem" />}>
                                        {translate('resources.conversations.copy_url')}
                                    </Button>
                                </CopyToClipboard>
                            </Box>
                            {messages && (
                                <Box>
                                    <CopyToClipboard text={messages} onCopy={() => handleCopy('messages')}>
                                        <Button size="small" variant="outlined" startIcon={<IconMessage size="1.2rem" />}>
                                            {translate('resources.conversations.copy_messages')}
                                        </Button>
                                    </CopyToClipboard>
                                </Box>
                            )}
                            <Box>
                                
                                <Button 
                                    size="small" 
                                    variant="outlined" 
                                    color="error"
                                    startIcon={<IconTrash size="1.2rem" />}
                                    onClick={handleDelete}
                                >
                                    {translate('resources.conversations.delete')}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};
