import { useEffect } from 'react';
import { LayoutProps, useLocaleState, useAuthProvider, CheckForApplicationUpdate, AppBar } from 'react-admin';
import CustomMenu from './CustomMenu';
import { Box, Drawer, IconButton, useMediaQuery, useTheme, AppBar as MuiAppBar, Toolbar, Divider, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useSidebarState } from 'react-admin';
import UserMenu from './UserMenu';
import { IconMenu2 } from '@tabler/icons-react';
import BonnieIcon from './BonnieIcon';
import Logo from './Logo';
import { useLocation } from 'react-router-dom';

const formatTitle = (path: string) => {
    const segment = path.split('/')[1] || 'Dashboard';
    return segment
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export default ({ children, ...props }: LayoutProps) => {
    const authProvider = useAuthProvider();
    const [currentLocale, setLocale] = useLocaleState();
    const [sidebarOpen, setSidebarOpen] = useSidebarState();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const title = formatTitle(location.pathname);
    
    const drawerWidth = 220;
    const collapsedWidth = 65;

    // Auth effect
    useEffect(() => {
        if (authProvider && authProvider.getIdentity) {
            authProvider.getIdentity().then(({ locale }) => {
                let dbLocale = locale.replace('-','_');
                if (dbLocale !== currentLocale) {
                    setLocale(dbLocale || 'en_US');
                }
            });
        }
    }, [authProvider, setLocale]);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <Box sx={{ 
            display: 'flex', 
            minHeight: '100vh',
            backgroundColor: !isMobile ? theme.palette.background.paper : theme.palette.background.default,
            overflow: 'hidden'
        }}>
            {isMobile && <AppBar 
                userMenu={false}
                toolbar={
                    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Typography variant="h6" component="div">
                            {title}
                        </Typography>
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            minWidth: '100px'
                        }}>
                            <BonnieIcon width={50} />
                        </Box>
                    </Toolbar>
                }
            >
                <div />
            </AppBar>}

            <Drawer
                variant={!isMobile ? "permanent" : "temporary"}
                open={!isMobile ? true : sidebarOpen}
                onClose={toggleSidebar}
                sx={{
                    width: !isMobile ? (sidebarOpen ? drawerWidth : collapsedWidth) : drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        position: 'relative',
                        width: !isMobile ? (sidebarOpen ? drawerWidth : collapsedWidth) : drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: theme.palette.background.paper,
                        borderRight: 'none',
                        overflowX: 'hidden',
                        minWidth: !isMobile ? (sidebarOpen ? '200px' : '65px') : '200px',
                        maxWidth: !isMobile ? (sidebarOpen ? '400px' : '65px') : '400px',
                        height: '100%',
                        transition: theme => theme.transitions.create(['width', 'min-width', 'max-width'], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex', 
                        flexDirection: 'row', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        pt: 2, 
                        pb: 2,
                        gap: 5
                    }}
                >
                    {sidebarOpen && (
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Logo width={100}/>
                        </Box>
                    )}
                    <IconButton onClick={toggleSidebar}>
                        {sidebarOpen ? <ChevronLeftIcon /> : <IconMenu2 />}
                    </IconButton>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '100%'}}>
                    <CustomMenu />
                    <Box sx={{ width: '100%', p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Divider sx={{ width: '100%', mb: 2 }} />
                        <UserMenu />
                    </Box>
                </Box>
            </Drawer>

            <Box
                component="main"
                sx={{
                    width: isMobile ? '100%' : `calc(100% - ${sidebarOpen ? '200px' : '65px'})`,
                    height: '100vh',
                    pt: isMobile ? '64px' : 2,
                    pb: !isMobile ? 2 : 0,
                    pr: !isMobile ? 2 : 0,
                }}
            >
                <Box
                    sx={{
                        backgroundColor: isMobile ? undefined : theme.palette.background.default,
                        borderRadius: 2,
                        height: '100%',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
                        overflow: 'scroll',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    {children}
                    <CheckForApplicationUpdate />
                </Box>
            </Box>
        </Box>
    );
};