import {
    List,
    Toolbar,
    SaveButton,
    DeleteButton,
    Datagrid,
    TextField,
    BooleanField,
    DateField,
    SimpleForm,
    TextInput,
    BooleanInput,
    RadioButtonGroupInput,
    useRecordContext,
    ReferenceManyField,
    ReferenceField,
    useGetIdentity,
    TopToolbar,
    useTranslate,
    ReferenceInput,
    AutocompleteInput,
    useGetOne,
    useShowController,
    useUpdate
} from 'react-admin';

import { CreateInDialogButton, EditInDialogButton } from '@react-admin/ra-form-layout';
import CustomPagination from './components/react-admin/CustomPagination';
import { ResourceTitle } from './layout/ResourceTitle';
import { useState } from 'react';
import { FiltersDrawer } from './components/FiltersDrawer';
import { TaskFilters } from './components/tasks/TaskFilters';
import { Box, Drawer, Typography, Paper, Button, Grid, IconButton } from '@mui/material';
import { IconX, IconMessageDots, IconLock, IconLockOpen, IconUser, IconCalendar, IconFlag, IconPencil, IconExternalLink } from '@tabler/icons-react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useUsersWithPollie } from './hooks/useUsersWithPollie';
import { ConversationNoteEditModal } from './components/ConversationNoteEditModal';


const statuses = [{id:'none',name:'resources.conversation_notes.statuses.none'},{id:'open',name:'resources.conversation_notes.statuses.open'},{id:'closed',name:'resources.conversation_notes.statuses.closed'}]

export const CustomEditToolbar = () => (
    <Toolbar sx={{ justifyContent: 'space-between' }}>
        <SaveButton />
        <DeleteButton redirect={false} />
    </Toolbar>
);

const AddNoteForm = () => {
    const { data: identity } = useGetIdentity();
    const record = useRecordContext();
    const { data: assistant } = useGetOne(
        'assistants',
        { id: record?.assistant_id },
        { enabled: !!record?.assistant_id }
    );

    const { users, isLoading: usersLoading } = useUsersWithPollie({
        customerId: assistant?.customer_id
    });
    
    return (
        <CreateInDialogButton fullWidth label="resources.conversation_notes.add_note" ButtonProps={{ variant: 'text' }} title="resources.conversation_notes.add_note">
            <SimpleForm
                defaultValues={{
                    created_by: identity?.id, 
                    conversation_id: record?.id, 
                }}        
            >
                <TextInput source="content" label="resources.conversation_notes.fields.content" required fullWidth multiline inputProps={{ style: { minHeight: '150px' } }} />
                <BooleanInput source="is_private" label="resources.conversation_notes.fields.is_private" />
                <RadioButtonGroupInput source="status" choices={statuses} defaultValue={'open'} label="resources.conversation_notes.fields.status" />
                <ReferenceInput source="assigned_to" reference="users">
                    {usersLoading ? (
                        <span>Loading users...</span>
                    ) : (
                        <AutocompleteInput 
                            source="assigned_to"
                            choices={users}
                            optionText={(choice) => `${choice.first_name} ${choice.last_name} (${choice.email})`} 
                            label="resources.conversation_notes.fields.assigned_to"
                            filterToQuery={(searchText) => ({
                                first_name: searchText ? `%${searchText}%` : undefined,
                                customer_id: assistant?.customer_id
                            })}
                            fullWidth
                        />
                    )}
                </ReferenceInput>
            </SimpleForm>
        </CreateInDialogButton>
    );
};

const EditNoteButton = () => {  
    const note = useRecordContext();
    
    // Haal conversation data op
    const { data: conversation } = useGetOne(
        'conversations',
        { id: note?.conversation_id },
        { enabled: !!note?.conversation_id }
    );

    // Haal assistant data op
    const { data: assistant } = useGetOne(
        'assistants',
        { id: conversation?.assistant_id },
        { enabled: !!conversation?.assistant_id }
    );

    // Gebruik de nieuwe hook voor users
    const { users, isLoading: usersLoading } = useUsersWithPollie({
        customerId: assistant?.customer_id
    });

    return (
        <EditInDialogButton fullWidth inline resource='conversation-notes' title="resources.conversation_notes.edit_note">
            <SimpleForm toolbar={<CustomEditToolbar/>}>
                <TextInput 
                    source="content" 
                    required 
                    fullWidth 
                    multiline 
                    inputProps={{ style: { minHeight: '150px' } }} 
                    label="resources.conversation_notes.fields.content" 
                />
                <BooleanInput 
                    source="is_private" 
                    label="resources.conversation_notes.fields.is_private" 
                />
                <RadioButtonGroupInput 
                    source="status" 
                    choices={statuses} 
                    defaultValue={'none'} 
                    label="resources.conversation_notes.fields.status" 
                />
                <ReferenceInput source="assigned_to" reference="users">
                    {usersLoading ? (
                        <span>Loading users...</span>
                    ) : (
                        <AutocompleteInput 
                            source="assigned_to"
                            choices={users}
                            optionText={(choice) => `${choice.first_name} ${choice.last_name} (${choice.email})`} 
                            label="resources.conversation_notes.fields.assigned_to"
                            filterToQuery={(searchText) => ({
                                first_name: searchText ? `%${searchText}%` : undefined,
                                customer_id: assistant?.customer_id
                            })}
                            fullWidth
                        />
                    )}
                </ReferenceInput>
            </SimpleForm>
        </EditInDialogButton>
    );
};


export const Task = () => {
    const record = useRecordContext();
    if (!record) {
        return null;
    }
    return (
        <ReferenceManyField reference="conversation-notes" target="conversation_id" source="id">
            <AddNoteForm />
            <Datagrid bulkActionButtons={false} size="medium">
                <TextField source="content" label="resources.conversation_notes.fields.content" />
                <BooleanField source="is_private" label="resources.conversation_notes.fields.is_private" />
                <ReferenceField source="created_by" reference="users" link={false} label="resources.conversation_notes.fields.created_by" emptyText='resources.generic.unavailable'>
                    <TextField source="first_name" />
                </ReferenceField>
                <ReferenceField source="assigned_to" reference="users" link={false} label="resources.conversation_notes.fields.assigned_to" emptyText='resources.generic.unavailable'>
                    <TextField source="first_name" />
                </ReferenceField>
                <TextField source="status" label="resources.conversation_notes.fields.status" />
                <DateField source="created_at" label="resources.conversation_notes.fields.created_at" />
                <EditNoteButton/>
            </Datagrid>
        </ReferenceManyField>
    )
};


const TaskListActionValue = (id:any, resource:any, record:any) => {
    return `/tasks/${record.id}/show`;
};


const TaskListActions = ({ filters, filtersOpen, setFiltersOpen, setFilters }: {
    filters: any, 
    filtersOpen: boolean, 
    setFiltersOpen: (open: boolean) => void,
    setFilters: (filters: any) => void,
}) => (
    <TopToolbar
        sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        }}
    >
        <FiltersDrawer
            open={filtersOpen}
            onOpen={() => setFiltersOpen(true)}
            onClose={() => setFiltersOpen(false)}
            filters={filters}
            onFilterChange={setFilters}
            onClearFilters={() => setFilters({})}
            filterComponents={TaskFilters({ filters, onFilterChange: setFilters })}
        />
    </TopToolbar>
);

interface TaskRecord {
    id: number;
    content: string;
    status: 'open' | 'closed' | 'none';
    is_private: boolean;
    created_by: number;
    assigned_to: number | null;
    conversation_id: number;
    created_at: string;
}

interface User {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
}

export const TaskList = () => {
    const translate = useTranslate();
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filters, setFilters] = useState({});
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<TaskRecord | null>(null);
    const navigate = useNavigate();
    const [update] = useUpdate();
    const { users } = useUsersWithPollie({});

    const handleEditNote = (noteData: {
        title: string;
        status: 'open' | 'closed' | 'none';
        isPrivate: boolean;
        assignedTo?: string;
    }) => {
        if (!selectedRecord) return;
        
        update(
            'conversation-notes',
            {
                id: selectedRecord.id,
                data: {
                    content: noteData.title,
                    status: noteData.status,
                    is_private: noteData.isPrivate,
                    assigned_to: noteData.assignedTo ? parseInt(noteData.assignedTo, 10) : null,
                }
            }
        );
        setEditModalOpen(false);
    };

    const EditButton = () => {
        const record = useRecordContext<TaskRecord>();
        if (!record) return null;
        
        return (
            <IconButton 
                onClick={() => {
                    setSelectedRecord(record);
                    setEditModalOpen(true);
                }}
            >
                <IconPencil size={18} />
            </IconButton>
        );
    };

    const GoToConversationButton = () => {
        const record = useRecordContext<TaskRecord>();
        if (!record) return null;
        
        return (
            <IconButton 
                onClick={() => window.open(`/#/conversations/${record.conversation_id}/show?tab=notes`, '_blank')}
            >
                <IconExternalLink size={18} />
            </IconButton>
        );
    };

    return (
        <Box p={2}>
            <ResourceTitle/>
            <List
                resource='conversation-notes'
                empty={false}
                sort={{ field: 'created_at', order: 'DESC' }}
                actions={
                    <TaskListActions 
                        filters={filters} 
                        filtersOpen={filtersOpen} 
                        setFiltersOpen={setFiltersOpen}
                        setFilters={setFilters}
                    />
                }
                pagination={<CustomPagination />}
                filter={filters}
            >
                <Datagrid 
                    bulkActionButtons={false} 
                    size="medium"
                    sx={{
                        '& .column-conversation_id': {
                            maxWidth: '15em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        },
                        '& .column-content': {
                            maxWidth: '15em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }
                    }}
                >
                    <DateField source="created_at" label="resources.conversation_notes.fields.created_at" />
                    <TextField source="content" label="resources.conversation_notes.fields.content" />
                    <ReferenceField source="conversation_id" reference="conversations" label="Assistant" link={false}>
                        <ReferenceField source="assistant_id" reference="assistants">
                            <TextField source="name" />
                        </ReferenceField>
                    </ReferenceField>
                    <BooleanField source="is_private" label="resources.conversation_notes.fields.is_private" />
                    <ReferenceField source="created_by" reference="users" link={false} label="resources.conversation_notes.fields.created_by">
                        <TextField source="first_name" />
                    </ReferenceField>
                    <ReferenceField source="assigned_to" reference="users" link={false} label="resources.conversation_notes.fields.assigned_to" emptyText='resources.generic.unavailable'>
                        <TextField source="first_name" />
                    </ReferenceField>
                    <TextField source="status" label="resources.conversation_notes.fields.status" />
                    <EditButton />
                    <GoToConversationButton />
                </Datagrid>
            </List>

            {selectedRecord && (
                <ConversationNoteEditModal
                    open={editModalOpen}
                    onClose={() => {
                        setEditModalOpen(false);
                        setSelectedRecord(null);
                    }}
                    onSubmit={handleEditNote}
                    note={{
                        id: selectedRecord.id.toString(),
                        title: selectedRecord.content || '',
                        status: selectedRecord.status || 'none',
                        isPrivate: selectedRecord.is_private || false,
                        assignedTo: selectedRecord.assigned_to?.toString() || '',
                    }}
                    users={users?.map(user => ({
                        id: user.id.toString(),
                        name: `${user.first_name} ${user.last_name}`,
                        email: user.email,
                    })) || []}
                />
            )}
        </Box>
    );
};

export const TaskShow = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const { record } = useShowController({ resource: 'conversation-notes' });
    const [update] = useUpdate();
    const theme = useTheme();
    const translate = useTranslate();

    // Add these hooks to fetch the assistant and users
    const { data: conversation } = useGetOne(
        'conversations',
        { id: record?.conversation_id },
        { enabled: !!record?.conversation_id }
    );

    const { users, isLoading: usersLoading } = useUsersWithPollie({
        customerId: conversation?.customer_id
    });

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            navigate('/tasks', {
                replace: true,
                state: { _scrollToTop: false }
            });
        }, 300);
    };

    const handleGoToConversation = () => {
        if (record?.conversation_id) {
            navigate(`/conversations/${record.conversation_id}/show`);
        }
    };

    const handleEditNote = (noteData: any) => {
        if (!record) return;
        
        update(
            'conversation-notes',
            {
                id: record.id,
                data: {
                    content: noteData.title,
                    status: noteData.status,
                    is_private: noteData.isPrivate,
                    assigned_to: noteData.assignedTo ? parseInt(noteData.assignedTo, 10) : null,
                }
            }
        );
        setEditModalOpen(false);
    };

    if (!record) return null;

    return (
        <>
            <TaskList />
            <Drawer
                anchor="right"
                open={open}
                onClose={handleClose}
                SlideProps={{
                    appear: true,
                    direction: "left",
                    timeout: 300
                }}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: {
                            xs: '100%',
                            sm: '100%',
                            md: '700px',
                        },
                        padding: theme.spacing(2, 3),
                        bgcolor: theme.palette.background.paper,
                    },
                }}
            >
                <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center',
                        mb: theme.spacing(3)
                    }}>
                        <Typography variant="h6" fontWeight="bold">
                            Task Details
                        </Typography>
                        <IconX
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={handleClose}
                            size={24}
                        />
                    </Box>

                    <Paper sx={{
                        p: theme.spacing(2),
                        boxShadow: theme.shadows[0],
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        bgcolor: 'white',
                    }}>
                        <Box sx={{ 
                            flexGrow: 1,
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: theme.spacing(2)
                        }}>
                            <Box>
                                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: theme.spacing(2) }}>
                                    Content
                                </Typography>
                                <Paper sx={{ 
                                p: 2, 
                                bgcolor: theme.palette.mode === 'dark' 
                                    ? theme.palette.grey[800] 
                                    : theme.palette.grey[100],
                                }}>
                                    <Typography>
                                        {record.content}
                                    </Typography>
                                </Paper>
                            </Box>

                            <Box>
                                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: theme.spacing(2) }}>
                                    Details
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
                                            <IconUser size="1rem" style={{ marginRight: 8 }} />
                                            <Typography variant="body2" color="textSecondary">
                                                Created by:
                                            </Typography>
                                        </Box>
                                        <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                            <Typography>
                                                {record.created_by || 'Unknown'}
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
                                            <IconCalendar size="1rem" style={{ marginRight: 8 }} />
                                            <Typography variant="body2" color="textSecondary">
                                                Created at:
                                            </Typography>
                                        </Box>
                                        <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                            <Typography>
                                                {new Date(record.created_at).toLocaleString()}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
                                            <IconMessageDots size="1rem" style={{ marginRight: 8 }} />
                                            <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                                Status:
                                            </Typography>
                                        </Box>
                                        <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    update('conversation-notes', {
                                                        id: record.id,
                                                        data: { 
                                                            status: record.status === 'open' ? 'closed' : 'open' 
                                                        },
                                                        previousData: record,
                                                    });
                                                }}
                                                startIcon={record.status === 'open' ? <IconLock size="1rem" /> : <IconLockOpen size="1rem" />}
                                            >
                                                {record.status === 'open' ? 'Close Task' : 'Reopen Task'}
                                            </Button>
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
                                            <IconFlag size="1rem" style={{ marginRight: 8 }} />
                                            <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                                Assigned to:
                                            </Typography>
                                        </Box>
                                        <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                            <Typography>
                                                {record.assigned_to || 'Unassigned'}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </Drawer>
            <ConversationNoteEditModal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                onSubmit={handleEditNote}
                note={{
                    id: record.id,
                    title: record.content || '',
                    status: record.status || 'none',
                    isPrivate: record.is_private || false,
                    assignedTo: record.assigned_to ? record.assigned_to.toString() : '',
                }}
                users={users?.map(user => ({
                    id: user.id.toString(),
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                }))}
            />
        </>
    );
};

export default TaskList;