import { Card, CardContent, Typography, Box, useTheme, Tooltip, IconButton } from "@mui/material";
import { Icon } from "@mui/material";
import { useTranslate, useLocaleState } from 'react-admin';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface TotalCallsCardProps {
  total: number;
  title: string;
  icon: React.ReactNode;
  unit?: string;
  suffix?: string;
  tooltip?: string;
}

export const TotalCallsCard = ({ 
  total, 
  title, 
  icon, 
  unit = '', 
  suffix = '',
  tooltip
}: TotalCallsCardProps) => {
  const theme = useTheme();
  const [locale] = useLocaleState();

  const formatNumber = (value: number) => {
    const safeLocale = locale?.replace('_', '-') || 'nl-NL';
    
    try {
      return new Intl.NumberFormat(safeLocale, {
        maximumFractionDigits: 0
      }).format(value);
    } catch (error) {
      return value.toString();
    }
  };

  return (
    <Card 
      sx={{
        height: '100%',
        minHeight: 120,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: `${theme.palette.primary.main}12`,
        border: `1px solid ${theme.palette.primary.main}40`,
        boxShadow: `0 2px 4px ${theme.palette.primary.main}12`
      }}
    >
      <CardContent 
        sx={{ 
          py: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Tooltip title={tooltip || ''} arrow placement="top">
          <Box sx={{ minHeight: 50 }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Icon sx={{ flexShrink: 0 }}>{icon}</Icon>
              <Typography 
                variant="body1" 
                component="div"
                sx={{ 
                  wordBreak: 'break-word',
                  hyphens: 'auto'
                }}
              >
                {title}
              </Typography>
            </Box>
          </Box>
        </Tooltip>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}>
          <Typography variant="h3" component="div" sx={{ display: 'flex', alignItems: 'baseline' }}>
            {formatNumber(total)}
            {(unit || suffix) && (
              <Typography component="span" variant="h6" sx={{ ml: 1, color: 'text.secondary' }}>
                {unit || suffix}
              </Typography>
            )}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}; 