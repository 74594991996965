import { useTranslate, FunctionField, RecordContextProvider, ReferenceField, TextField } from 'react-admin';
import { Box, Paper, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconInfoCircle, IconPhone, IconClock, IconCalendar, IconUser, IconFlag, IconTag, IconMessageDots, IconSparkles } from '@tabler/icons-react';
import CustomChip from '../react-admin/CustomChip';
import DurationField from '../../DurationField';
import { LocalCreatedAt } from '../../conversations';
import TagsManager from '../../TagsManager';
import { outcomeConfig, OutcomeType } from '../../config/outcomeConfig';

interface ConversationRecord {
    outcome: OutcomeType;
    meta?: string;
    summary?: string;
    phone_number?: string;
    from_name?: string;
    duration?: number;
    created_at?: string;
    messages?: string;
    audio_url?: string;
    topic?: string;
    tags?: string[];
    assistant_name?: string;
    assistant_id?: string;
}

export const ConversationDetails = ({ record }: { record: ConversationRecord }) => {
    const translate = useTranslate();
    const theme = useTheme();

    if (!record) return null;

    return (
        <RecordContextProvider value={record}>
            <Paper sx={{
                p: theme.spacing(2),
                boxShadow: theme.shadows[0],
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Box sx={{ 
                    flexGrow: 1,
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                }}>
                    <Box>
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: 'bold',
                                mb: theme.spacing(2)
                            }}
                        >
                            {translate('resources.conversations.fields.summary')}
                        </Typography>
                        <Paper 
                            sx={{ 
                                p: 2, 
                                bgcolor: theme.palette.mode === 'dark' 
                                    ? theme.palette.grey[800] 
                                    : theme.palette.grey[100],
                            }}
                        >
                            <Typography>
                                {record.summary || translate('resources.conversations.no_summary')}
                            </Typography>
                        </Paper>
                    </Box>

                    <Box>
                        <Typography variant="h6" fontWeight="bold" fontSize="1.25rem" mb={theme.spacing(1)}>
                        {translate('resources.conversations.fields.details')}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
                                        <IconUser size="1rem" style={{ marginRight: 8 }} />
                                        <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                            {translate('resources.conversations.fields.name')}:
                                        </Typography>
                                    </Box>
                                    <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                        <Typography>{record.from_name || translate('resources.conversations.unknown')}</Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
                                        <IconPhone size="1rem" style={{ marginRight: 8 }} />
                                        <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                            {translate('resources.conversations.fields.phone_number')}:
                                        </Typography>
                                    </Box>
                                    <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                        <Typography>
                                            <a 
                                                href={`tel:${record.from_id}`}
                                                style={{ 
                                                    textDecoration: 'none',
                                                    color: theme.palette.primary.main
                                                }}
                                            >
                                                {record.from_id || translate('resources.conversations.unknown')}
                                            </a>
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
                                        <IconCalendar size="1rem" style={{ marginRight: 8 }} />
                                        <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                            {translate('resources.conversations.fields.created_at')}:
                                        </Typography>
                                    </Box>
                                    <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                        <LocalCreatedAt source="created_at" />
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
                                        <IconClock size="1rem" style={{ marginRight: 8 }} />
                                        <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                            {translate('resources.conversations.fields.duration')}:
                                        </Typography>
                                    </Box>
                                    <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                        <DurationField source="duration" />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
                                    <IconSparkles size="1rem" style={{ marginRight: 8 }} />
                                    <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                        {translate('resources.conversations.fields.assistant_id')}:
                                    </Typography>
                                </Box>
                                <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                <ReferenceField source="assistant_id" reference="assistants"><TextField source="name" /></ReferenceField>                        

                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
                                    <IconMessageDots size="1rem" style={{ marginRight: 8 }} />
                                    <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                        {translate('resources.conversations.fields.topic')}:
                                    </Typography>
                                </Box>
                                <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                    <Typography>{record.topic || translate('resources.conversations.unknown')}</Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
                                    <IconFlag size="1rem" style={{ marginRight: 8 }} />
                                    <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                        {translate('resources.conversations.fields.outcome')}
                                    </Typography>
                                </Box>
                                <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                    <Box sx={{ display: 'flex', gap: theme.spacing(1), flexWrap: 'wrap' }}>
                                        <FunctionField
                                            render={(record: ConversationRecord) => {
                                                const config = outcomeConfig[record.outcome];
                                                const Icon = config?.icon || IconInfoCircle;
                                                return (
                                                    <CustomChip
                                                        label={translate(`resources.conversations.outcomes.${record.outcome}`)}
                                                        variant="outlined"
                                                        color="default"
                                                        icon={<Icon size={16} stroke={1.5} color={config?.color} />}
                                                    />
                                                );
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IconTag size="1rem" style={{ marginRight: 8 }} />
                                    <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                        {translate('resources.conversations.fields.tags')}:
                                    </Typography>
                                </Box>
                                <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                    <TagsManager record={record} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Paper>
        </RecordContextProvider>
    );
};