import * as React from 'react';
import { Loading, useGetManyAggregate, TopToolbar, useRecordContext,ReferenceArrayInput, SelectInput, DateInput, PrevNextButtons, AutocompleteArrayInput, SelectArrayInput, usePermissions, FunctionField, useTranslate, DateField, List, SimpleList, ReferenceField, TextField, useShowController, useGetOne, useGetList, useDataProvider, TextInput, useNotify, Form, useListContext, FilterLiveForm } from 'react-admin';
import { Box, Paper, Tabs, Tab, Drawer, Typography } from '@mui/material';
import DurationField from './DurationField';
import { useMediaQuery } from '@mui/material';
import { useNavigate, Route, Routes, useParams, useSearchParams } from 'react-router-dom';
import { IconX, IconClipboardList, IconMessageDots, IconNote, IconSettings } from '@tabler/icons-react';
import CustomPagination from './components/react-admin/CustomPagination';
import CustomChip from './components/react-admin/CustomChip';
import { useTheme } from '@mui/material/styles';
import { RatingBadge } from './components/RatingBadge';
import { allTagsWithEmoji } from './TagsManager';
import { Datagrid } from 'react-admin';
import { ConversationDetails } from './components/conversations/ConversationDetails';
import ConversationTranscript from './components/conversations/ConversationTranscript';
import AudioPlayer from './components/AudioPlayer';
import ConversationNotes from './components/conversations/ConversationNotes';
import { useEffect } from 'react';
import { ConversationAdmin } from './components/conversations/ConversationAdmin';
import { outcomeConfig, OutcomeType } from './config/outcomeConfig';
import { Tooltip } from '@mui/material';
import { ResourceTitle } from './layout/ResourceTitle';
import { useFilters } from './contexts/FilterContext';
import { IconUser, IconBuilding, IconCalendar, IconTags, IconMessageCircle, IconPhone } from '@tabler/icons-react';
import { FiltersDrawer } from './components/FiltersDrawer';
import { DateRangeFilter } from './components/dashboard/DateRangeFilter';
import { format } from 'date-fns';

const tags = Object.keys(allTagsWithEmoji).map(key => ({ id: key, name: key }));

export const TagsManagerWrapper = () => {
    const record = useRecordContext();
    if (!record?.tags) return null;
    if (typeof record.tags === 'string') {
        record.tags = [record.tags];
    }

    return (
        <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
            {record.tags.map((tag: string) => (
                <CustomChip
                    key={tag}
                    label={allTagsWithEmoji[tag] || tag}
                    variant="filled"
                    size="small"
                />
            ))}
        </div>
    );
};

export const LocalCreatedAt = (props: any) => {
    const record = useRecordContext();
    const { data: assistants, isLoading, error } = useGetManyAggregate('assistants', { ids: [record?.assistant_id] });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
            <DateField
            source={props.source}
            options={{ year: 'numeric', month: 'numeric', day: 'numeric',hour: '2-digit', minute: '2-digit', timeZone: assistants?.[0]?.timezone }}
            transform={(value: string) => new Date(value+'+00:00')}
            />
        )
};



const filterToQuery = (searchText: any) => ({ name: `%${searchText}%` });



const Assistant = () => {
    const record = useRecordContext();
    const { data: assistants, isLoading, error } = useGetManyAggregate('assistants', { ids: [record?.assistant_id] });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <>{assistants?.[0]?.name}</>;
};

const ConversationFilterButton = () => {
    const [open, setOpen] = React.useState(false);
    const { filterValues, setFilters } = useListContext();
    const { setFilters: setContextFilters } = useFilters();
    const translate = useTranslate();
    const theme = useTheme();
    const { permissions } = usePermissions();

    const handleFilterChange = (values: any) => {
        const cleanedValues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => 
                value !== undefined && 
                value !== null && 
                value !== '' && 
                (Array.isArray(value) ? value.length > 0 : true)
            )
        );
        setFilters(cleanedValues);
        if (Object.keys(cleanedValues).length > 0) {
            setContextFilters(cleanedValues);
        }
    };

    const handleClearFilters = () => {
        setFilters({});
        setContextFilters({});
    };

    const filterComponents = [
        <Box key="assistant">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <IconUser size="1rem" style={{ marginRight: 8 }} />
                <Typography variant="body2" color="textSecondary">
                    {translate('resources.filters.assistant')}:
                </Typography>
            </Box>
            <Box sx={{mb: 2 }}>
                <ReferenceArrayInput source="assistant_id" reference="assistants">
                    <AutocompleteArrayInput 
                        optionText="name"
                        fullWidth
                        helperText={false}
                        filterToQuery={filterToQuery}
                        defaultValue={filterValues.assistant_id}
                        onChange={(value) => handleFilterChange({ ...filterValues, assistant_id: value })}
                    />
                </ReferenceArrayInput>
            </Box>
        </Box>,

        <Box key="customer">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <IconBuilding size="1rem" style={{ marginRight: 8 }} />
                <Typography variant="body2" color="textSecondary">
                    {translate('resources.filters.customer')}:
                </Typography>
            </Box>
            <Box sx={{mb: 2 }}>
                <ReferenceArrayInput source="assistant.customer_id" reference="customers">
                    <AutocompleteArrayInput 
                        optionText="name"
                        fullWidth
                        helperText={false}
                        filterToQuery={filterToQuery}
                        defaultValue={filterValues['assistant.customer_id']}
                        onChange={(value) => handleFilterChange({ ...filterValues, 'assistant.customer_id': value })}
                    />
                </ReferenceArrayInput>
            </Box>
        </Box>,

        <Box key="content">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <IconMessageCircle size="1rem" style={{ marginRight: 8 }} />
                <Typography variant="body2" color="textSecondary">
                    {translate('resources.filters.content')}:
                </Typography>
            </Box>
            <Box sx={{mb: 2 }}>
                <TextInput 
                    source="topic" 
                    fullWidth 
                    helperText={false}
                    defaultValue={filterValues.topic}
                    onChange={(e) => handleFilterChange({ ...filterValues, topic: e.target.value })}
                />
                <Box mt={1}>
                    <TextInput 
                        source="summary" 
                        fullWidth 
                        helperText={false}
                        defaultValue={filterValues.summary}
                        onChange={(e) => handleFilterChange({ ...filterValues, summary: e.target.value })}
                    />
                </Box>
            </Box>
        </Box>,

        <Box key="contact">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <IconPhone size="1rem" style={{ marginRight: 8 }} />
                <Typography variant="body2" color="textSecondary">
                    {translate('resources.filters.contact')}:
                </Typography>
            </Box>
            <Box sx={{mb: 2 }}>
                <TextInput 
                    source="from_id" 
                    fullWidth 
                    helperText={false}
                    defaultValue={filterValues.from_id}
                    onChange={(e) => handleFilterChange({ ...filterValues, from_id: e.target.value })}
                />
            </Box>
        </Box>,

        <Box key="tags">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <IconTags size="1rem" style={{ marginRight: 8 }} />
                <Typography variant="body2" color="textSecondary">
                    {translate('resources.filters.tags')}:
                </Typography>
            </Box>
            <Box sx={{mb: 2 }}>
                <SelectArrayInput 
                    source="tags" 
                    choices={tags} 
                    variant='outlined' 
                    fullWidth 
                    helperText={false}
                    defaultValue={filterValues.tags}
                    onChange={(event) => {
                        const selectedTags = Array.isArray(event.target.value) ? event.target.value : [];
                        handleFilterChange({ ...filterValues, tags: selectedTags });
                    }}
                />
            </Box>
        </Box>,

        <Box key="outcome">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <IconMessageCircle size="1rem" style={{ marginRight: 8 }} />
                <Typography variant="body2" color="textSecondary">
                    {translate('resources.filters.outcome')}:
                </Typography>
            </Box>
            <Box sx={{mb: 2 }}>
                <SelectInput
                    source="outcome"
                    emptyText="resources.conversations.outcomes.all"
                    choices={[        
                        { id: 'completed', name: 'resources.conversations.outcomes.completed' },
                        { id: 'caller_follow_up', name: 'resources.conversations.outcomes.caller_follow_up' },
                        { id: 'business_follow_up', name: 'resources.conversations.outcomes.business_follow_up' },
                        { id: 'transferred', name: 'resources.conversations.outcomes.transferred' },
                        { id: 'information_provided', name: 'resources.conversations.outcomes.information_provided' },
                        { id: 'no_action', name: 'resources.conversations.outcomes.no_action' },
                    ]}
                    fullWidth
                    helperText={false}
                    defaultValue={filterValues.outcome}
                    onChange={(event) => {
                        handleFilterChange({ ...filterValues, outcome: event.target.value })}}
                />
            </Box>
        </Box>,

        <Box key="date">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <IconCalendar size="1rem" style={{ marginRight: 8 }} />
                <Typography variant="body2" color="textSecondary">
                    {translate('resources.filters.date_range')}:
                </Typography>
            </Box>
            <Box sx={{mb: 2 }}>
                <DateRangeFilter 
                    onChange={(dates) => handleFilterChange({ 
                        ...filterValues,
                        date_from: dates.start ? format(new Date(dates.start), 'yyyy-MM-dd') : undefined,
                        date_to: dates.end ? format(new Date(dates.end), 'yyyy-MM-dd') : undefined,
                    })} 
                    value={filterValues.date_from ? [
                        new Date(filterValues.date_from),
                        new Date(filterValues.date_to || filterValues.date_from)
                    ] : undefined}
                />
            </Box>
        </Box>
    ];

    return (
        <FiltersDrawer
                open={open}
                onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            filters={filterValues}
            onFilterChange={handleFilterChange}
            onClearFilters={handleClearFilters}
            filterComponents={filterComponents}
            showActiveFilters={true}
        />
    );
};

const ListActions = () => (
    <TopToolbar>
        <ConversationFilterButton />
    </TopToolbar>
);

interface Filters {
  date_from?: string;
  date_to?: string;
  assistant_id?: number[];
  'assistant.customer_id'?: number[];
  outcome?: string;
}

export const ConversationList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const theme = useTheme();
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <Box p={2}>
            <ResourceTitle />
            <List
                exporter={false} 
                sort={{ field: 'created_at', order: 'DESC' }}
                actions={<ListActions />}
                pagination={<CustomPagination />}
            >
                {isSmall ? (
                    <SimpleList
                        primaryText={<LocalCreatedAt source="created_at" label="resources.assistants.fields.created_at" />}
                        secondaryText={record => 
                            <>
                            <strong>{translate('resources.conversations.fields.outcome')}:</strong> {record.outcome ? translate('resources.conversations.outcomes.'+record.outcome) : <i>n/a</i>}
                            <br />
                            <strong>{translate('resources.conversations.fields.topic')}:</strong> {record.topic ? record.topic : <i>n/a</i>}
                            <br />
                            {['admin', 'partner'].includes(permissions) && <TagsManagerWrapper />}
                            <br />
                            <strong>{translate('resources.conversations.fields.summary')}:</strong> {record.summary ? record.summary : <i>n/a</i>}
                            </>
                        }
                        tertiaryText={record => 
                            <div style={{ textAlign: 'right', fontSize: '0.875rem' }}>
                            {record.from_id ? record.from_id : <i>n/a</i>}
                            {record.from_name ? ' - ' + record.from_name : ''}
                            <br />
                            <Assistant />
                            </div>
                        }
                        linkType={record => record.canEdit ? "edit" : "show"} 
                    />
                ) : (
                    <Datagrid 
                        rowClick={(id, resource, record) => {
                            const currentParams = new URLSearchParams(searchParams);
                            currentParams.set('tab', 'overview');
                            return `${id}/show?${currentParams.toString()}`;
                        }} 
                        bulkActionButtons={false}
                        sx={{
                            '& .column-summary': {
                                '& > *': {
                                    whiteSpace: 'normal', 
                                    overflow: 'hidden', 
                                    WebkitLineClamp: 2, 
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    lineHeight: '1.2em',
                                    maxWidth: '15em',
                                }
                            },
                            '& .column-phone_number': {
                                display: 'table-cell',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '8em',
                            },
                            '& .column-outcome': {
                                display: 'table-cell',
                                maxWidth: '10em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            },
                            '& .column-tags': {
                                display: 'table-cell',
                                maxWidth: '15em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            },
                            '& .column-assistant_id': {
                                display: 'table-cell',
                                maxWidth: '12em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            },
                        }}
                    >
                        <FunctionField 
                            source="created_at" 
                            label="Creation time"
                            render={(record) => (
                                <div>
                                    <LocalCreatedAt source="created_at" />
                                    <div style={{ color: theme.palette.text.primary, marginTop: theme.spacing(0.5) }}>
                                        <DurationField source="duration" />
                                    </div>
                                </div>
                            )}
                        />
                        <FunctionField
                            source="from_id"
                            label="Contact"
                            render={(record) => (
                                <div>
                                    {record.from_id}
                                    <div style={{ color: theme.palette.text.primary, marginTop: theme.spacing(0.5) }}>
                                        {record.from_name || 'Unknown'}
                                    </div>
                                </div>
                            )}
                        />
                        <TextField source="topic" label="Topic" />
                        <TextField source="summary" label="Summary" />
                        <FunctionField
                            source="outcome"
                            label="Outcome"
                            render={(record: Record<string, any>) => {
                                if (!record?.outcome) return null;
                                const config = outcomeConfig[record.outcome as OutcomeType] || outcomeConfig['no_action'];
                                const Icon = config.icon;

                                return (
                                    <Tooltip 
                                        title={translate(`resources.conversations.outcomes.${record.outcome}`)}
                                        placement="top"
                                        arrow
                                        PopperProps={{
                                            sx: {
                                                zIndex: 1300
                                            }
                                        }}
                                    >
                                        <span style={{ 
                                            display: 'inline-block',
                                            cursor: 'pointer'
                                        }}>
                                            <CustomChip 
                                                variant="outlined"
                                                color={'default'}
                                                icon={<Icon size={config.size} stroke={1.5} color={config.color}/>}
                                                label=""
                                                sx={{
                                                    '& .MuiChip-label': { display: 'none' },
                                                    height: 24,
                                                    minWidth: 24,
                                                    '& .MuiChip-icon': {
                                                        marginLeft: '0',
                                                        marginRight: '0',
                                                    },
                                                    padding: 0
                                                }}
                                            />
                                        </span>
                                    </Tooltip>
                                );
                            }}
                        />
                        <FunctionField source="tags" label="Tags" render={record => <TagsManagerWrapper />} />
                        <ReferenceField source="assistant_id" reference="assistants" label="Assistant">
                            <TextField source="name" />
                        </ReferenceField>
                        {permissions === 'admin' && 
                            <FunctionField 
                                label="Rating" 
                                render={(record: any) => {
                                    const rating = JSON.parse(record.meta).rating;
                                    return rating ? <RatingBadge value={rating} /> : null;
                                }} 
                            />
                        }
                    </Datagrid>
                )}
            </List>
            <Routes>
                <Route
                    path=":id"
                    element={<ConversationShow />}
                />
            </Routes>
        </Box>
    );
};

export const ConversationShow = () => {
    const translate = useTranslate();
    const { record } = useShowController();
    const { data: assistants } = useGetManyAggregate('assistants', { ids: record ? [record.assistant_id] : [] });
    const { permissions } = usePermissions();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [open, setOpen] = React.useState(true);
    const selectedTab = searchParams.get('tab') || 'overview';
    const theme = useTheme();
    const { filters: contextFilters, setFilters: setContextFilters } = useFilters();
    const urlFilters = searchParams.get('filter') ? JSON.parse(searchParams.get('filter')!) : {};
    const currentFilter = Object.keys(urlFilters).length > 0 ? urlFilters : contextFilters || {};

    // Ensure filters are in both URL and context
    useEffect(() => {
        if (Object.keys(currentFilter).length > 0) {
            // Update URL if filters exist but not in URL
            if (Object.keys(urlFilters).length === 0) {
                const newParams = new URLSearchParams(searchParams);
                newParams.set('filter', JSON.stringify(currentFilter));
                setSearchParams(newParams);
            }
            // Update context if needed
            if (JSON.stringify(contextFilters) !== JSON.stringify(currentFilter)) {
                setContextFilters(currentFilter);
            }
        }
    }, [currentFilter, searchParams]);

    const dataProvider = useDataProvider();
    const { data: listData } = useGetList('conversations', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'created_at', order: 'DESC' },
        filter: currentFilter
    });

    const apiUrl = import.meta.env.VITE_API_URL;
    const auth = localStorage.getItem('auth');
    const parsedAuth = JSON.parse(auth || '{}');

    useEffect(() => {
        const handleKeyPress = async (event: KeyboardEvent) => {
            if (!record || !listData) return;

            const filteredList = listData.filter((item: any) => {
                const itemTags = item.tags || [];
                const currentFilter = searchParams.get('filter') ? JSON.parse(searchParams.get('filter')!) : {};
                return (
                    (!currentFilter.assistant_id || currentFilter.assistant_id.includes(item.assistant_id)) &&
                    (!currentFilter.tags || currentFilter.tags.every((tag: string) => itemTags.includes(tag)))
                );
            });

            const currentIndex = filteredList.findIndex((item: any) => item.id === record.id);

            if (event.key === 'ArrowRight' && currentIndex < filteredList.length - 1) {
                const currentParams = new URLSearchParams(searchParams);
                navigate(`/conversations/${filteredList[currentIndex + 1].id}/show?${currentParams.toString()}`, {
                    replace: true,
                    state: { _scrollToTop: false }
                });
            } else if (event.key === 'ArrowLeft' && currentIndex > 0) {
                const currentParams = new URLSearchParams(searchParams);
                navigate(`/conversations/${filteredList[currentIndex - 1].id}/show?${currentParams.toString()}`, {
                    replace: true,
                    state: { _scrollToTop: false }
                });
            }

            if (permissions === 'admin' && selectedTab === 'admin') {
                const rating = parseInt(event.key);
                if (rating >= 1 && rating <= 5) {
                    try {
                        const meta = JSON.parse(record.meta || '{}');
                        meta.rating = rating;
                        const newMeta = JSON.stringify(meta);

                        await dataProvider.update('conversations', {
                            id: record.id,
                            data: { meta: newMeta },
                            previousData: record,
                        });

                        setOpen(false);
                        setTimeout(() => {
                            navigate('/conversations', {
                                replace: true,
                                state: { _scrollToTop: false }
                            });
                        }, 300);
                    } catch (error) {
                        console.error('Error updating rating:', error);
                    }
                }
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [record, listData, navigate, permissions, selectedTab, dataProvider, searchParams]);

    const handleTabChange = (_: any, newValue: string) => {
        const currentParams = new URLSearchParams(searchParams);
        currentParams.set('tab', newValue);
        setSearchParams(currentParams);
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            const currentParams = new URLSearchParams(searchParams);
            currentParams.delete('tab');
            // Always preserve the current filters in URL when going back
            if (Object.keys(currentFilter).length > 0) {
                currentParams.set('filter', JSON.stringify(currentFilter));
                // Also ensure they're in context
                setContextFilters(currentFilter);
            }
            navigate({
                pathname: '/conversations',
                search: currentParams.toString()
            }, {
                replace: true,
                state: { 
                    _scrollToTop: false,
                    preserveFilter: true,
                    preservePagination: true
                }
            });
        }, 300);
    };

    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

    const getCurrentConversationNumber = () => {
        if (!record || !listData) return null;
        
        const filteredList = listData.filter((item: any) => {
            const itemTags = item.tags || [];
            const currentFilter = searchParams.get('filter') ? JSON.parse(searchParams.get('filter')!) : {};
            return (
                (!currentFilter.assistant_id || currentFilter.assistant_id.includes(item.assistant_id)) &&
                (!currentFilter.tags || currentFilter.tags.every((tag: string) => itemTags.includes(tag)))
            );
        });
        
        return {
            current: filteredList.findIndex((item: any) => item.id === record.id) + 1,
            total: filteredList.length
        };
    };

    const conversationNumber = getCurrentConversationNumber();

    return (
        <>
            <ConversationList />
            <Drawer
                anchor="right"
                open={open}
                onClose={handleClose}
                SlideProps={{
                    appear: true,
                    direction: "left",
                    timeout: 300
                }}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: {
                            xs: '100%',
                            sm: '100%',
                            md: '700px',
                        },
                        padding: {
                            xs: 2,
                            sm: 2,
                            md: 3,
                        },
                        backgroundColor: theme.palette.background.paper,
                    },
                }}
            >
                <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}>
                    <Box sx={{ mb: 3, position: 'relative' }}>
                        <IconX
                            style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                cursor: 'pointer',
                            }}
                            onClick={handleClose}
                            size={24}
                        />
                        <Typography variant="h6" fontWeight="bold" fontSize="1.25rem">
                            {translate('resources.conversations.conversation_details')}: {assistants?.[0]?.name}
                        </Typography>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <AudioPlayer 
                            src={`${apiUrl}/conversations/${record?.id}/recording.mp3?access_token=${parsedAuth.access_token}`}
                        />
                    </Box>

                    <Paper sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        mb: 2,
                    }}>
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            variant={isSmall ? "scrollable" : "fullWidth"}
                            scrollButtons={isSmall ? "auto" : false}
                        >
                            <Tab
                                icon={<IconClipboardList size="1rem" />}
                                label="Overview"
                                value="overview"
                                iconPosition="start"
                            />
                            <Tab
                                icon={<IconMessageDots size="1rem" />}
                                label="Transcript"
                                value="transcript"
                                iconPosition="start"
                            />
                            <Tab
                                icon={<IconNote size="1rem" />}
                                label="Notes"
                                value="notes"
                                iconPosition="start"
                            />
                            {record?.meta && permissions === 'admin' && (
                                <Tab
                                    icon={<IconSettings size="1rem" />}
                                    label="Admin"
                                    value="admin"
                                    iconPosition="start"
                                />
                            )}
                        </Tabs>
                    </Paper>
                    <Box sx={{ 
                        overflow: 'auto',
                        flexGrow: 1,
                    }}>
                        {selectedTab === 'overview' && 
                            <ConversationDetails 
                                record={record} 
                                conversationNumber={conversationNumber}
                            />
                        }
                        {selectedTab === 'transcript' && <ConversationTranscript messages={record?.messages} />}
                        {selectedTab === 'notes' && <ConversationNotes record={record} />}
                        {selectedTab === 'admin' && record?.meta && 
                            <ConversationAdmin 
                                meta={record.meta} 
                                messages={record.messages} 
                                id={record.id} 
                            />
                        }
                    </Box>
                </Box>
            </Drawer>
        </>
    );
};

export const TwilioRedirect = () => {
    const { twilio_id } = useParams();
    const navigate = useNavigate();

    const { data, isLoading, error } = useGetOne(
        'conversations',
        { id: twilio_id },
        {
            onSuccess: (data) => {
                if (data?.id) {
                    navigate(`/conversations/${data.id}/show`, { replace: true });
                }
            },
            onError: () => {
                navigate('/conversations', { replace: true });
            }
        }
    );

    if (isLoading) return null;
};

const ConversationShowActions = () => {
    const { permissions } = usePermissions();
    const notify = useNotify();
    const record = useRecordContext();
    const translate = useTranslate();

    const prevNextProps = {
        filter: {},
        sort: { field: 'created_at', order: 'DESC' },
        linkType: 'show'
    };

    return (
        <TopToolbar>
            {permissions === 'admin' && <PrevNextButtons {...prevNextProps} />}
        </TopToolbar>
    );
};